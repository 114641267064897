import Link from 'next/link'
import React, { memo, useMemo } from 'react'

import { ContentCard } from '@/components/elements/cards/contentCard'
import { TechnologiesAvatarGroup } from '@/components/elements/images/technologiesAvatarGroup'
import Thumbnail from '@/components/elements/thumbnail'
import ConditionalWrapper from '@/components/hoc/conditionalWrapper'
import { getAttributesNames } from 'utils/helpers'
import { Tags } from '@/components/elements/tags'
import { dateFormat } from 'utils/dates'

export const projectMocked = {
  attributes: {
    name: '',
    description: '',
    individuals: [],
    cover: {},
    technologies: []
  }
}

const _ProjectCard = ({ project, className, clamp, isShimmer }) => {
  const { id, attributes } = project
  const {
    name,
    description,
    cover,
    technologies,
    industries,
    start_date,
    due_date
  } = attributes

  const href = id ? `/projects/${id}` : undefined

  const technologyAvatars = useMemo(
    () => (
      <TechnologiesAvatarGroup
        technologies={technologies.data}
        maxPopoverTrigger="hover"
      />
    ),
    [technologies]
  )
  const projectIndustry = getAttributesNames(industries)[0]

  const title = (
    <div className="project-card-title">
      <h2 className="content-card-title">{name}</h2>
      <div className="content-card-date">{projectIndustry}</div>
    </div>
  )

  return (
    <ConditionalWrapper
      condition={href}
      wrapper={children => <Link href={href}>{children}</Link>}
    >
      <div className="project-container">
        <ContentCard
          cover={<Thumbnail image={cover?.data?.attributes ?? ''} />}
          title={title}
          description={description}
          className={className}
          clamp={clamp}
          isShimmer={isShimmer || !name}
          bottom={technologyAvatars}
        />
      </div>
    </ConditionalWrapper>
  )
}

export const ProjectCard = memo(_ProjectCard)
