import { useMockUpFetch } from 'common/hooks'
import isEmpty from 'lodash/isEmpty'
import { memo } from 'react'
import { createGetDataEndpoint } from 'utils/endpoints'

import Card from '@/components/elements/card'
import ProjectGallery from '@/components/elements/gallery/projectGallery'
import HeaderOverhaul from '@/components/elements/headerOverhaul'
import { projectMocked } from '@/components/sections/projects/projectCard'
import { Button } from '@/components/elements/buttons/button'
import Link from 'next/link'

const NO_PROJECTS = "Couldn't find any project at the moment"

const HomeLatestProjects = ({ data: { header, numberOfProjects } }) => {
  const { state: projects } = useMockUpFetch({
    limit: numberOfProjects,
    mocked: projectMocked,
    endpoint: createGetDataEndpoint({
      endpoint: 'projects',
      limit: numberOfProjects,
      sortBy: 'start_date'
    })
  })

  return (
    <div className="latest-projects-wrapper">
      <HeaderOverhaul header={header} subtitle />
      <Link href="/projects">
        <Button text="See more" className="latest-projects-button" />
      </Link>
      {!isEmpty(projects) ? (
        <div className="latest-projects-container unselectable">
          <ProjectGallery projects={projects} />
        </div>
      ) : (
        <Card placeholder={NO_PROJECTS} />
      )}
    </div>
  )
}

export default memo(HomeLatestProjects)
