import { Tag } from 'antd'
import classNames from 'classnames'

export const Tags = ({ items, className, ...props }) => {
  const classes = classNames('tags-container', className)

  if (!items) return null

  return (
    <div className={classes}>
      {items.map(item => (
        <Tag key={item} {...props}>
          {item}
        </Tag>
      ))}
    </div>
  )
}
