import classNames from 'classnames'
import React from 'react'

import ScrollDrag from '../hoc/dragScroll'
import CustomImage from './images/image'

const Thumbnail = ({ className, tags, image, tagsScroll = true }) => {
  const classes = classNames('thumbnail-card-cover', className)
  return (
    <div className={classes}>
      {tags && (
        <div className="thumbnail-tag-container unselectable">
          <ScrollDrag enabled={tagsScroll} gap>
            {tags.map(tag => (
              <div className="thumbnail-card-tag" key={tag}>
                {tag}
              </div>
            ))}
          </ScrollDrag>
        </div>
      )}
      <CustomImage
        media={image}
        layout="fill"
        objectPosition="center"
        objectFit="cover"
      />
    </div>
  )
}

export default Thumbnail
