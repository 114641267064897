import { memo, useCallback } from 'react'

import Scroller from '@/components/elements/gallery/scroller'
import { ProjectCard } from '@/components/sections/projects/projectCard'

const ProjectGallery = ({
  projects,
  arrows = true,
  gradient = true,
  hideScrollbarDesktop = true
}) => {
  const renderProject = useCallback(
    (item, index) => (
      <ProjectCard key={`project-${index}`} project={item} tagsScroll={false} clamp />
    ),
    []
  )

  return (
    <Scroller
      array={projects}
      render={renderProject}
      arrows={arrows}
      gradient={gradient}
      hideScrollbarDesktop={hideScrollbarDesktop}
    />
  )
}

export default memo(ProjectGallery)
